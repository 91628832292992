import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Layout } from "../components";
import { getContent } from "../helper";
import { AboutLayout } from "../layouts";

const About = props => {
  const data = getContent(props?.data);

  return (
    <Layout title="About" {...props}>
      {" "}
      <Helmet>
        <meta
          name="description"
          content="If you listen carefully, our tiny Shih Tzu puppies for sale have a lovely story to tell. Come learn with us about our Shih Tzu breed and why they make a great addition to any family!"
        />{" "}
        <meta
          name="keywords"
          content="Imperial Shih Tzu Puppies for Sale, shih tzu puppies for sale los angeles, shih tzu for sale san diego, Teacuo Shih Tzu Puppies For Sale, AKC Shih Tzu Puppies for sale, Shih Tzu Puppies for Sale, Tiny Shih Tzu Puppies for sale, Shih Tzu Puppies for sale in Fullerton, Shih Tzu Puppies for sale in Irvine, Shih Tzu Puppies for sale in Newport Beach, Shih Tzu Puppies for sale in Los Angeles, Shih Tzu Puppies for sale in Mission Viejo, Shih Tzu Puppies for sale in Lake Forest, Shih Tzu Puppies for sale in Brea, Shih Tzu Puppies For Sale in La Habra Heights, Shih Tzu Puppies for Sale in Costa Mesa, Shih Tzu Puppies for sale in Las Vegas, Shih Tzu Puppies for Sale in Anaheim Hills"
        />
      </Helmet>
      <AboutLayout {...data} />
    </Layout>
  );
};

export default About;

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/about/" } }) {
      nodes {
        html
        frontmatter {
          title
          contact
        }
      }
    }
  }
`;
